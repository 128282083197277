import { getTranslation } from '@mahawi/eshop-common/dist/src/translation';
import {
  EEventContextAction,
  EEventType,
  type IBrand,
  type ICategory,
} from '@mahawi/eshop-common/dist/src/types';
import { type Dispatch } from '@reduxjs/toolkit';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getHead } from '../../head';
import LinearProgress from '../components/linear-progress';
import Breadcrumbs from '../components/product/breadcrumbs';
import Grid from '../components/products/grid';
import LanguageMutationLinks from '../components/products/language-mutation-links';
import Pagination from '../components/products/pagination';
import { type ICategoryState } from '../reducers/category/types';
import { eventSend } from '../reducers/event/reducer';
import { type ILanguageState } from '../reducers/language/types';
import { load, loadBybrand } from '../reducers/products/reducer';
import { type IProductsState } from '../reducers/products/types';

const BRAND_FILTER_DEFAULT = '-';

function ProductsContainer({
  Category,
  Products,
  dispatch,
  Language,
}: {
  Category: ICategoryState;
  Products: IProductsState;
  dispatch: Dispatch;
  Language: ILanguageState;
}): JSX.Element {
  const [brandUUID, setBrandUUID] = useState(BRAND_FILTER_DEFAULT);

  const { categoryUUID, pageUrl } = useParams();
  const { t } = useTranslation();

  const pageInt: number = parseInt(pageUrl || '1', 10);

  useEffect((): (() => void) => {
    dispatch(
      eventSend({
        type: EEventType.PAGE_PRODUCTS,
        action: EEventContextAction.ENTER,
        context: {
          page: { number: pageInt },
          category: { uuid: categoryUUID },
        },
      }),
    );

    return (): void => {
      dispatch(
        eventSend({
          type: EEventType.PAGE_PRODUCTS,
          action: EEventContextAction.LEAVE,
          context: {
            page: { number: pageInt },
            category: { uuid: categoryUUID },
          },
        }),
      );
    };
  }, [dispatch, pageInt, categoryUUID]);

  useEffect((): void => {
    if (categoryUUID !== Products.category.uuid) {
      setBrandUUID(BRAND_FILTER_DEFAULT);
    }
  }, [categoryUUID, Products.category.uuid]);

  useEffect((): void => {
    if (!categoryUUID) {
      return;
    }

    if (categoryUUID !== Products.category.uuid || pageInt !== Products.page) {
      if (brandUUID === BRAND_FILTER_DEFAULT) {
        dispatch(
          load({
            category: { uuid: categoryUUID },
            page: pageInt,
          }),
        );
      } else {
        dispatch(
          loadBybrand({
            category: { uuid: categoryUUID },
            brand: { uuid: brandUUID },
            page: pageInt,
          }),
        );
        dispatch(
          eventSend({
            type: EEventType.PRODUCTS_FILTER_BY_BRAND,
            action: EEventContextAction.FILTER,
            context: { brand: { uuid: brandUUID } },
          }),
        );
      }
    }

    if (
      categoryUUID === Products.category.uuid &&
      Products.products.length === 0
    ) {
      dispatch(
        load({
          category: { uuid: categoryUUID },
          page: pageInt,
        }),
      );
    }
  }, [
    brandUUID,
    categoryUUID,
    pageInt,
    Products.category.uuid,
    Products.page,
    dispatch,
  ]);

  if (Products.isLoading) {
    return <LinearProgress />;
  }

  const category: ICategory | undefined = Category.categories.find(
    (c: ICategory): boolean => c.uuid === categoryUUID,
  );

  const categoryName: string | null = getTranslation(
    category?.names,
    Language.languageType,
  );

  if (typeof document !== 'undefined' && categoryName) {
    const { title } = getHead(t, categoryName, pageInt);
    document.title = title;
  }

  const header: string =
    Products.pages === 1
      ? `${categoryName || ''}`
      : `${categoryName} [${pageInt} / ${Products.pages}]`;

  return (
    <>
      <div className="container">
        <Breadcrumbs />
        <h1>{header}</h1>
      </div>

      <div className="container filter">
        <Formik
          enableReinitialize
          initialValues={{
            brandUUID,
          }}
          onSubmit={(): void => {}}
        >
          {(): boolean | undefined | JSX.Element =>
            category?.brands &&
            category.brands.length > 1 && (
              <Form>
                <div className="select">
                  <label htmlFor="brandUUID-simple">
                    <Trans>layout.filter.brandName</Trans>
                  </label>
                  <Field
                    as="select"
                    name="brandUUID"
                    id="brandUUID-simple"
                    onChange={({ target }): void => {
                      const brandUUIDChanged: string = target.value;
                      setBrandUUID(brandUUIDChanged);

                      if (!categoryUUID) {
                        return;
                      }

                      if (brandUUIDChanged === BRAND_FILTER_DEFAULT) {
                        dispatch(
                          load({
                            category: { uuid: categoryUUID },
                            page: pageInt,
                          }),
                        );
                      } else {
                        dispatch(
                          loadBybrand({
                            category: { uuid: categoryUUID },
                            brand: { uuid: brandUUIDChanged },
                            page: pageInt,
                          }),
                        );
                        dispatch(
                          eventSend({
                            type: EEventType.PRODUCTS_FILTER_BY_BRAND,
                            action: EEventContextAction.FILTER,
                            context: { brand: { uuid: brandUUIDChanged } },
                          }),
                        );
                      }
                    }}
                  >
                    <option
                      value={BRAND_FILTER_DEFAULT}
                      key={BRAND_FILTER_DEFAULT}
                    >
                      ---
                    </option>
                    {category.brands.map(
                      (c: IBrand): JSX.Element => (
                        <option value={c.uuid} key={c.uuid}>
                          {c.name}
                        </option>
                      ),
                    )}
                  </Field>
                </div>
              </Form>
            )
          }
        </Formik>
      </div>

      <Grid products={Products.products} headerLevel={2} />

      <Pagination categoryUUID={categoryUUID} />

      {category && <LanguageMutationLinks category={category} />}
    </>
  );
}

const mapStateToProps = ({ Category, Language, Products }) => ({
  Category,
  Language,
  Products,
});

export default connect(mapStateToProps)(ProductsContainer);
