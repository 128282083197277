import { calculateDeliveryFee } from '@mahawi/eshop-common/dist/src/calculate-delivery-fee';
import { formatCurrency } from '@mahawi/eshop-common/dist/src/format-currency';
import { round } from '@mahawi/eshop-common/dist/src/round';
import {
  EEventContextAction,
  EEventType,
  EOrderAction,
  EPaymentVendorType,
  type IBasketPrice,
  type IBasketProduct,
  type ICalculatedDeliveryFee,
  type ICountry,
  type ICurrencyPrice,
  type ICustomerCurrencyType,
  type IOrderValidationFormErrors,
  type ITranslation,
} from '@mahawi/eshop-common/dist/src/types';
import { type Dispatch } from '@reduxjs/toolkit';
import { Field, Form, Formik } from 'formik';
import React, {
  type BaseSyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getHead } from '../../head';
import Input from '../components/input';
import {
  addProduct,
  removeProduct,
  setDelivery,
  setFields,
  validateDiscountCode,
} from '../reducers/basket/reducer';
import { type IBasketState } from '../reducers/basket/types';
import { type ICountryState } from '../reducers/country/types';
import { type ICurrencyState } from '../reducers/currency/types';
import { eventSend } from '../reducers/event/reducer';
import { type ILanguageState } from '../reducers/language/types';
import { create as createOrder } from '../reducers/order/reducer';
import { type IOrderState } from '../reducers/order/types';
import { load } from '../reducers/product/reducer';
import { setActiveCategory } from '../reducers/products/reducer';
import { type IUserState } from '../reducers/user/types';
import { type ITagManager } from '../types';

function BasketContainer({
  Basket,
  dispatch,
  Country,
  Order,
  User,
  Currency,
  Language,
  TagManager,
}: {
  Basket: IBasketState;
  dispatch: Dispatch;
  Country: ICountryState;
  Order: IOrderState;
  User: IUserState;
  Currency: ICurrencyState;
  Language: ILanguageState;
  TagManager: ITagManager | undefined;
}): JSX.Element {
  const { t } = useTranslation();

  const [basketPrice, setBasketPrice] = useState<IBasketPrice | undefined>(
    undefined,
  );
  const [currency, setCurrentCurrency] = useState<
    ICustomerCurrencyType | undefined
  >(undefined);
  const [isModalOpened, setModalOpen] = useState(false);
  const [languageCodeLower, setLanguageCodeLower] = useState<string>(
    Language.languageType.code.toLowerCase(),
  );
  const [totalGoodsWeight, setTotalGoodsWeight] = useState<number>(0);
  const [purchased, setPurchased] = useState(false);
  const [beginCheckout, setBeginCheckout] = useState(false);
  const [paymentVendorType] = useState<EPaymentVendorType>(
    EPaymentVendorType.COMGATE,
  );

  const getItemsForTagManager = useCallback(() => {
    return Basket.products.map((product: IBasketProduct) => {
      const productNameEn: ITranslation | undefined = product.names.find(
        (n: ITranslation): boolean => n.code === 'EN',
      );
      const productPriceEur: ICurrencyPrice | undefined = product.prices.find(
        (c: ICurrencyPrice): boolean => c.isoCode === 'EUR',
      );

      return {
        item_id: product.uuid,
        item_name: productNameEn?.value || '---',
        price: productPriceEur?.price || 0,
        quantity: product.count,
        currency: 'EUR',
      };
    });
  }, [Basket.products]);

  useEffect((): void => {
    const { title } = getHead(t, t('navigation.title.basket'));
    document.title = title;
  }, [t]);

  useEffect(() => {
    dispatch(
      eventSend({
        type: EEventType.PAGE_BASKET,
        action: EEventContextAction.ENTER,
      }),
    );
    dispatch(
      setActiveCategory({
        category: {
          uuid: '',
        },
      }),
    );

    return (): void => {
      dispatch(
        eventSend({
          type: EEventType.PAGE_BASKET,
          action: EEventContextAction.LEAVE,
        }),
      );
    };
  }, [dispatch]);

  useEffect((): void => {
    const currencyUE: ICustomerCurrencyType | undefined =
      Currency.currencies.find(
        (c: ICustomerCurrencyType): boolean =>
          c.isoCode === User.currencyType?.isoCode,
      );

    setCurrentCurrency(currencyUE);

    const basketPriceUE: IBasketPrice | undefined = Basket.basketPrices.find(
      ({ isoCode }: IBasketPrice): boolean =>
        isoCode === User.currencyType?.isoCode,
    );

    if (basketPriceUE) {
      setBasketPrice(basketPriceUE);
    }
  }, [
    dispatch,
    Currency.currencies,
    User.currencyType?.isoCode,
    Basket.basketPrices,
  ]);

  useEffect((): void => {
    setLanguageCodeLower(Language.languageType.code.toLowerCase());
  }, [Language.languageType.code]);

  useEffect((): void => {
    const totalGoodsWeightUE: number = round(
      Basket.products.reduce(
        (acc: number, product: IBasketProduct): number =>
          acc + product.goodsWeight,
        0,
      ),
      4,
    );

    setTotalGoodsWeight(totalGoodsWeightUE);
  }, [Basket.products]);

  useEffect((): void => {
    let deliveryAddressIsoCode: string = Basket.deliveryAddressIsDifferent
      ? Basket.deliveryAddress.countryTypeIsoCode
      : Basket.billingAddress.countryTypeIsoCode;

    if (Basket.deliveryAddressIsDifferent && deliveryAddressIsoCode === '...') {
      deliveryAddressIsoCode = Basket.billingAddress.countryTypeIsoCode;
    }

    if (
      currency &&
      deliveryAddressIsoCode &&
      deliveryAddressIsoCode !== '...'
    ) {
      const delivery: ICalculatedDeliveryFee = calculateDeliveryFee(
        deliveryAddressIsoCode,
        Country.deliveries,
        currency.isoCode,
        totalGoodsWeight,
        [],
        [],
      );

      dispatch(setDelivery(delivery));
    }
  }, [
    Basket.deliveryAddressIsDifferent,
    Basket.deliveryAddress.countryTypeIsoCode,
    Basket.billingAddress.countryTypeIsoCode,
    Country.deliveries,
    currency,
    dispatch,
    totalGoodsWeight,
  ]);

  useEffect((): void => {
    if (purchased && TagManager) {
      const items = getItemsForTagManager();

      TagManager.dataLayer({
        dataLayer: {
          event: 'purchase',
          ecommerce: {
            items,
          },
          currency: 'EUR',
          transaction_id: Order.uuid,
        },
      });
    }
  }, [purchased, TagManager, Order.uuid, getItemsForTagManager]);

  useEffect((): void => {
    if (beginCheckout && TagManager) {
      const items = getItemsForTagManager();

      TagManager.dataLayer({
        dataLayer: {
          event: 'begin_checkout',
          ecommerce: {
            items,
          },
        },
      });
    }
  }, [beginCheckout, TagManager, getItemsForTagManager]);

  const initialValuesOrder = {
    billingAddress: Basket.billingAddress,
    deliveryAddress: Basket.deliveryAddress,
    deliveryAddressIsDifferent: Basket.deliveryAddressIsDifferent,
    heurekaCZShopCertification: Basket.heurekaCZShopCertification,
    packagesCount: Basket.packagesCount,
    termsAndConditions: Basket.termsAndConditions,
    totalDeliveryFee: Basket.totalDeliveryFee,
    discount: {
      code: Basket.discount.code,
      value: 0,
      discountType: {
        code: '',
      },
    },
  };

  if (Order.redirectLink !== '') {
    dispatch(
      eventSend({
        type: EEventType.ORDER_PAYMENT_REDIRECTION,
        action: EEventContextAction.REDIRECT,
      }),
    );

    window.location.href = Order.redirectLink;
  }

  const countryErrorMessage: string = t('basket.countryIsNotSet');

  const changeProductCount = (productUUID: string, count: number): void => {
    dispatch(
      addProduct({
        product: { uuid: productUUID },
        count,
        discount: {
          code: Basket.discount.code,
        },
      }),
    );

    dispatch(
      eventSend({
        action: EEventContextAction.CHANGE,
        type: EEventType.PAGE_BASKET_CHANGE_PRODUCT_COUNT,
        context: {
          product: { uuid: productUUID },
          count,
        },
      }),
    );

    if (TagManager) {
      const items = getItemsForTagManager();

      TagManager.dataLayer({
        dataLayer: {
          event: 'add_to_cart',
          ecommerce: {
            items,
          },
        },
      });
    }
  };

  return (
    <div className="basket container">
      <div className={`modal ${isModalOpened ? 'open' : 'close'}`}>
        {t(
          paymentVendorType === EPaymentVendorType.COMGATE
            ? 'basket.contactingComgate'
            : 'basket.contactingPayPal',
        )}
      </div>

      <h1>{t('basket.productsInBasket')}</h1>

      {/* <div className="notification">
        <span>
          {t('basket.vacation', {
            dateFrom: '21.8.2024',
            dateTo: '1.9.2024',
            processedWhen: '2.9.2024',
          })}
        </span>
      </div> */}

      {Basket.products.length === 0 ? (
        <p>{t('basket.isEmpty')}</p>
      ) : (
        <>
          <table className="orderTable">
            <thead>
              <tr>
                <th>{t('basket.productName')}</th>
                <th>{t('basket.count')}</th>
                <th>{t('basket.pricePerUnit')}</th>
                <th>{t('basket.totalPrice')}</th>
                <th>{t('basket.totalWeight')}</th>
                <th>{t('basket.action')}</th>
              </tr>
            </thead>
            <tbody>
              {Basket.products.map(
                (product: IBasketProduct): JSX.Element | null => {
                  const productPrice: ICurrencyPrice | undefined =
                    product.prices.find(
                      (c: ICurrencyPrice): boolean =>
                        c.isoCode === User.currencyType?.isoCode,
                    );

                  if (!productPrice) {
                    return null;
                  }

                  const { price } = productPrice;

                  const slug: ITranslation | undefined = product.slugs.find(
                    ({ code }: ITranslation): boolean =>
                      code === Language.languageType.code,
                  );

                  let link: string = '';

                  if (slug?.value) {
                    link = `/${languageCodeLower}/p/${slug.value}`;
                  } else {
                    link = `/${languageCodeLower}/product/${product.uuid}`;
                  }

                  return (
                    <tr key={product.uuid}>
                      <td>
                        <Link to={link}>
                          <button
                            type="button"
                            onClick={(): void => {
                              dispatch(
                                load({
                                  product: {
                                    uuid: product.uuid,
                                  },
                                }),
                              );
                            }}
                          >
                            {product.names.find(
                              (n: ITranslation): boolean =>
                                n.code === Language.languageType.code,
                            )?.value || ''}
                          </button>
                        </Link>
                      </td>

                      <td>
                        <button
                          type="button"
                          className="arrow"
                          onClick={() => changeProductCount(product.uuid, -1)}
                          disabled={product.count <= 1}
                          aria-label="Decrease product count"
                        >
                          {product.count > 1 && (
                            <svg className="arrowDownOutlined">
                              <path d="M12 21.312l-7.121-7.121c-1.17-1.17-1.17-3.073 0-4.242 1.094-1.094 2.978-1.138 4.121-.115v-4.834c0-1.654 1.346-3 3-3s3 1.346 3 3v4.834c1.143-1.023 3.027-.979 4.121.115 1.17 1.169 1.17 3.072 0 4.242l-7.121 7.121zm-5-10.242c-.268 0-.518.104-.707.293-.391.39-.391 1.023 0 1.414l5.707 5.707 5.707-5.707c.391-.391.391-1.024 0-1.414-.379-.379-1.035-.379-1.414 0l-3.293 3.293v-9.656c0-.551-.448-1-1-1s-1 .449-1 1v9.656l-3.293-3.293c-.189-.189-.439-.293-.707-.293z" />
                            </svg>
                          )}
                        </button>
                        {product.count}
                        <button
                          type="button"
                          className="arrow"
                          onClick={(): void =>
                            changeProductCount(product.uuid, 1)
                          }
                          aria-label="Increase product count"
                        >
                          <svg className="arrowUpOutlined">
                            <path d="M12 21c-1.654 0-3-1.346-3-3v-4.764c-1.143 1.024-3.025.979-4.121-.115-1.17-1.169-1.17-3.073 0-4.242l7.121-7.121 7.121 7.121c1.17 1.169 1.17 3.073 0 4.242-1.094 1.095-2.979 1.14-4.121.115v4.764c0 1.654-1.346 3-3 3zm-1-12.586v9.586c0 .551.448 1 1 1s1-.449 1-1v-9.586l3.293 3.293c.379.378 1.035.378 1.414 0 .391-.391.391-1.023 0-1.414l-5.707-5.707-5.707 5.707c-.391.391-.391 1.023 0 1.414.379.378 1.035.378 1.414 0l3.293-3.293z" />
                          </svg>
                        </button>
                      </td>
                      <td>{formatCurrency(price, currency)}</td>
                      <td>{` ${
                        currency
                          ? formatCurrency(price * product.count, currency)
                          : round(price * product.count, 2)
                      } `}</td>
                      <td>{`${product.goodsWeight} kg`}</td>
                      <td>
                        <button
                          type="button"
                          className="action"
                          onClick={(): void => {
                            dispatch(
                              removeProduct({
                                product: { uuid: product.uuid },
                                discount: {
                                  code: Basket.discount.code,
                                },
                              }),
                            );
                            dispatch(
                              eventSend({
                                action: EEventContextAction.REMOVE,
                                type: EEventType.PRODUCT_REMOVED_FROM_BASKET,
                                context: { product: { uuid: product.uuid } },
                              }),
                            );
                          }}
                          aria-label="Remove product from basket"
                        >
                          <svg className="deleteOutlined">
                            <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  );
                },
              )}
            </tbody>
          </table>

          <h2>{t('basket.totalGoodsWeight', { totalGoodsWeight })}</h2>

          <Formik
            enableReinitialize
            initialValues={initialValuesOrder}
            onSubmit={({
              billingAddress,
              deliveryAddress,
              deliveryAddressIsDifferent,
              heurekaCZShopCertification,
            }) => {
              setPurchased(true);
              setModalOpen(true);

              if (!currency) {
                return;
              }

              dispatch(
                createOrder({
                  billingAddress,
                  deliveryAddress,
                  deliveryAddressIsDifferent,
                  currencyType: currency,
                  languageType: Language.languageType,
                  heurekaCZShopCertification,
                  discount: Basket.discount,
                  paymentVendorTypeCode: paymentVendorType,
                  type: EOrderAction.CREATE,
                }),
              );

              dispatch(
                eventSend({
                  type: EEventType.PAGE_ORDER,
                  action: EEventContextAction.SUBMIT,
                }),
              );
            }}
            validate={({
              billingAddress,
              deliveryAddress,
              deliveryAddressIsDifferent,
              heurekaCZShopCertification,
              termsAndConditions,
              discount,
            }) => {
              setBeginCheckout(true);

              const emailPattern = /[\w\W]{1,}@[\w\W]{1,}\.[\w\W]{1,}/;
              let isValid = true;

              const errors: IOrderValidationFormErrors = {
                billingAddress: {
                  name: '',
                  surname: '',
                  email: '',
                  phone: '',
                  street: '',
                  city: '',
                  zip: '',
                  countryTypeIsoCode: '',
                },
                deliveryAddress: {
                  name: '',
                  surname: '',
                  email: '',
                  phone: '',
                  street: '',
                  city: '',
                  zip: '',
                  countryTypeIsoCode: '',
                },
              };

              if (billingAddress.name.length < 2) {
                isValid = false;
                errors.billingAddress.name = t('basket.nameIsTooShort');
              }

              if (billingAddress.surname.length < 2) {
                isValid = false;
                errors.billingAddress.surname = t('basket.surnameIsTooShort');
              }

              if (
                billingAddress.email.length < 6 ||
                !emailPattern.test(billingAddress.email)
              ) {
                isValid = false;
                errors.billingAddress.email = t('basket.emailIsNotValid');
              }

              if (billingAddress.phone.length < 8) {
                isValid = false;
                errors.billingAddress.phone = t('basket.phoneIsNotValid');
              }

              if (billingAddress.street.length < 2) {
                isValid = false;
                errors.billingAddress.street = t('basket.streetIsTooShort');
              }

              if (billingAddress.city.length < 2) {
                isValid = false;
                errors.billingAddress.city = t('basket.cityIsTooShort');
              }

              if (billingAddress.zip.length < 2) {
                isValid = false;
                errors.billingAddress.zip = t('basket.zipIsTooShort');
              }

              if (
                billingAddress.countryTypeIsoCode.length < 3 ||
                billingAddress.countryTypeIsoCode === '...'
              ) {
                isValid = false;
                errors.billingAddress.countryTypeIsoCode = t(
                  'basket.countryIsNotSet',
                );
              }

              if (deliveryAddressIsDifferent) {
                if (deliveryAddress.name.length < 2) {
                  isValid = false;
                  errors.deliveryAddress.name = t('basket.nameIsTooShort');
                }

                if (deliveryAddress.surname.length < 2) {
                  isValid = false;
                  errors.deliveryAddress.surname = t(
                    'basket.surnameIsTooShort',
                  );
                }

                if (
                  deliveryAddress.email.length < 6 ||
                  !emailPattern.test(deliveryAddress.email)
                ) {
                  isValid = false;
                  errors.deliveryAddress.email = t('basket.emailIsNotValid');
                }

                if (deliveryAddress.phone.length < 8) {
                  isValid = false;
                  errors.deliveryAddress.phone = t('basket.phoneIsNotValid');
                }

                if (deliveryAddress.street.length < 2) {
                  isValid = false;
                  errors.deliveryAddress.street = t('basket.streetIsTooShort');
                }

                if (deliveryAddress.city.length < 2) {
                  isValid = false;
                  errors.deliveryAddress.city = t('basket.cityIsTooShort');
                }

                if (deliveryAddress.zip.length < 2) {
                  isValid = false;
                  errors.deliveryAddress.zip = t('basket.zipIsTooShort');
                }

                if (
                  deliveryAddress.countryTypeIsoCode.length < 3 ||
                  deliveryAddress.countryTypeIsoCode === '...'
                ) {
                  isValid = false;
                  errors.deliveryAddress.countryTypeIsoCode = t(
                    'basket.countryIsNotSet',
                  );
                }
              }

              dispatch(
                setFields({
                  billingAddress,
                  deliveryAddress,
                  termsAndConditions,
                  deliveryAddressIsDifferent,
                  heurekaCZShopCertification,
                  discount,
                }),
              );

              if (!isValid) {
                dispatch(
                  eventSend({
                    type: EEventType.ORDER_VALIDATION,
                    action: EEventContextAction.VALIDATE,
                    context: {
                      isValid: false,
                      errors,
                    },
                  }),
                );
              }

              return isValid ? {} : errors;
            }}
          >
            {({ values, errors }) => (
              <Form>
                <div className="container--5 container--t order">
                  <h3>{t('basket.billingAddress')}</h3>

                  <Input
                    errorMessage={errors?.billingAddress?.name}
                    name="billingAddress.name"
                    label={t('basket.name')}
                  />

                  <Input
                    errorMessage={errors?.billingAddress?.surname}
                    name="billingAddress.surname"
                    label={t('basket.surname')}
                  />

                  <Input
                    errorMessage={errors?.billingAddress?.email}
                    name="billingAddress.email"
                    label={t('basket.email')}
                    type="email"
                  />

                  <Input
                    errorMessage={errors?.billingAddress?.phone}
                    name="billingAddress.phone"
                    label={t('basket.phone')}
                    type="tel"
                  />

                  <Input
                    errorMessage={errors?.billingAddress?.street}
                    name="billingAddress.street"
                    label={t('basket.street')}
                  />

                  <Input
                    errorMessage={errors?.billingAddress?.city}
                    name="billingAddress.city"
                    label={t('basket.city')}
                  />

                  <Input
                    errorMessage={errors?.billingAddress?.zip}
                    name="billingAddress.zip"
                    label={t('basket.zip')}
                  />

                  <label htmlFor="billingAddress.countryTypeIsoCode">
                    {t('basket.country')}

                    <Field
                      as="select"
                      id="billingAddress.countryTypeIsoCode"
                      name="billingAddress.countryTypeIsoCode"
                      className={
                        errors?.billingAddress?.countryTypeIsoCode
                          ? 'error'
                          : 'valid'
                      }
                    >
                      <option key="default">...</option>
                      {Country.countries.map(
                        (c: ICountry): JSX.Element => (
                          <option value={c.isoCode} key={c.isoCode}>
                            {`${c.name} (${c.isoCode})`}
                          </option>
                        ),
                      )}
                    </Field>

                    <p
                      className={`errorMessage ${
                        errors?.billingAddress?.countryTypeIsoCode
                          ? 'error'
                          : 'valid'
                      }`}
                    >
                      {errors?.billingAddress?.countryTypeIsoCode
                        ? countryErrorMessage
                        : '&nbsp;'}
                    </p>
                  </label>
                </div>

                {values.deliveryAddressIsDifferent && (
                  <div className="container--5 container--t order">
                    <h3>{t('basket.deliveryAddress')}</h3>

                    <Input
                      errorMessage={errors?.deliveryAddress?.name}
                      name="deliveryAddress.name"
                      label={t('basket.name')}
                    />

                    <Input
                      errorMessage={errors?.deliveryAddress?.surname}
                      name="deliveryAddress.surname"
                      label={t('basket.surname')}
                    />

                    <Input
                      errorMessage={errors?.deliveryAddress?.email}
                      name="deliveryAddress.email"
                      label={t('basket.email')}
                    />

                    <Input
                      errorMessage={errors?.deliveryAddress?.phone}
                      name="deliveryAddress.phone"
                      label={t('basket.phone')}
                    />

                    <Input
                      errorMessage={errors?.deliveryAddress?.street}
                      name="deliveryAddress.street"
                      label={t('basket.street')}
                    />

                    <Input
                      errorMessage={errors?.deliveryAddress?.city}
                      name="deliveryAddress.city"
                      label={t('basket.city')}
                    />

                    <Input
                      errorMessage={errors?.deliveryAddress?.zip}
                      name="deliveryAddress.zip"
                      label={t('basket.zip')}
                    />

                    <label htmlFor="deliveryAddress.countryTypeIsoCode">
                      {t('basket.country')}

                      <Field
                        as="select"
                        id="deliveryAddress.countryTypeIsoCode"
                        name="deliveryAddress.countryTypeIsoCode"
                        className={
                          errors?.deliveryAddress?.countryTypeIsoCode
                            ? 'error'
                            : 'valid'
                        }
                      >
                        <option key="default">...</option>
                        {Country.countries.map(
                          (c: ICountry): JSX.Element => (
                            <option value={c.isoCode} key={c.isoCode}>
                              {`${c.name} (${c.isoCode})`}
                            </option>
                          ),
                        )}
                      </Field>

                      <p
                        className={`errorMessage ${
                          errors?.deliveryAddress?.countryTypeIsoCode
                            ? 'error'
                            : 'valid'
                        }`}
                      >
                        {errors?.deliveryAddress?.countryTypeIsoCode
                          ? countryErrorMessage
                          : '&nbsp;'}
                      </p>
                    </label>
                  </div>
                )}

                <div className="container">
                  <label
                    className="label-checkbox"
                    htmlFor="deliveryAddressIsDifferent"
                  >
                    <Field
                      type="checkbox"
                      name="deliveryAddressIsDifferent"
                      id="deliveryAddressIsDifferent"
                      onClick={(e: BaseSyntheticEvent): void => {
                        dispatch(
                          eventSend({
                            type: EEventType.ORDER_DELIVERY_ADDRESS_IS_DIFFERENT,
                            action: EEventContextAction.CHANGE,
                            context: { isEnabled: !!e.target.checked },
                          }),
                        );
                      }}
                    />
                    {t('basket.deliveryAddressIsDifferent')}
                  </label>

                  <label
                    className="label-checkbox"
                    htmlFor="termsAndConditions"
                    style={{
                      color: values.termsAndConditions ? undefined : 'red',
                    }}
                  >
                    <Field
                      type="checkbox"
                      name="termsAndConditions"
                      id="termsAndConditions"
                      onClick={(e: BaseSyntheticEvent) => {
                        dispatch(
                          eventSend({
                            type: EEventType.ORDER_TERMS_AND_CONDITION,
                            action: EEventContextAction.CHANGE,
                            context: { isEnabled: !!e.target.checked },
                          }),
                        );
                      }}
                    />
                    {t('basket.acceptingTermsAndConditions')}
                    &nbsp;
                    <Link to={`/${languageCodeLower}/terms-and-conditions`}>
                      {t('basket.termsAndConditions')}
                    </Link>
                  </label>

                  {errors && errors.termsAndConditions && (
                    <p className="MuiFormHelperText-root Mui-error">
                      {errors.termsAndConditions}
                    </p>
                  )}

                  <label
                    className="label-checkbox"
                    htmlFor="heurekaCZShopCertification"
                  >
                    <Field
                      type="checkbox"
                      name="heurekaCZShopCertification"
                      id="heurekaCZShopCertification"
                      onClick={(e: BaseSyntheticEvent): void => {
                        dispatch(
                          eventSend({
                            type: EEventType.ORDER_HEUREKA_CZ_SHOP_CERTIFICATION,
                            action: EEventContextAction.CHANGE,
                            context: { isEnabled: !!e.target.checked },
                          }),
                        );
                      }}
                    />
                    {t('basket.heurekaCZShopCertification')}
                  </label>
                </div>

                <div className="container--5 order">
                  <Input
                    name="discount.code"
                    label={t('basket.discount.code')}
                  />

                  {Basket.discount.value > 0 ? (
                    <p>{t('basket.discount.isValid')}</p>
                  ) : (
                    <p>{t('basket.discount.isInvalid')}</p>
                  )}

                  {Basket.discount.value <= 0 && (
                    <button
                      type="button"
                      onClick={() => {
                        dispatch(
                          validateDiscountCode({
                            discount: {
                              code: Basket.discount.code,
                            },
                          }),
                        );
                        dispatch(
                          eventSend({
                            type: EEventType.ORDER_DISCOUNT_CODE_VALIDATION,
                            action: EEventContextAction.VALIDATE,
                            context: {
                              discount: { code: Basket.discount.code },
                            },
                          }),
                        );
                      }}
                    >
                      {t('basket.discount.validate')}
                    </button>
                  )}
                </div>

                {basketPrice && (
                  <div className="container">
                    <h2>{t('basket.summary')}</h2>

                    {Basket.discount.value > 0 && (
                      <>
                        <p>
                          {t('basket.totalPriceForGoodsBeforeDiscountIs', {
                            price: formatCurrency(
                              basketPrice.beforeDiscount,
                              currency,
                            ),
                          })}
                        </p>

                        <p>
                          {t('basket.totalDiscountForGoodsIs', {
                            price: formatCurrency(
                              basketPrice.discount,
                              currency,
                            ),
                          })}
                        </p>
                      </>
                    )}

                    <p>
                      {t('basket.totalPriceForGoodsIs', {
                        price: formatCurrency(basketPrice.total, currency),
                      })}
                    </p>

                    <p>{t('basket.totalGoodsWeight', { totalGoodsWeight })}</p>

                    <p>
                      {t('basket.totalDeliveryFee', {
                        // packagesCount: values.packagesCount,
                        packagesCount: 1,
                        totalDeliveryFee: formatCurrency(
                          values.totalDeliveryFee,
                          currency,
                        ),
                      })}
                    </p>

                    {/* <button
                      className={`order--button--payment-vendor ${
                        paymentVendorType === EPaymentVendorType.COMGATE
                          ? 'active'
                          : ''
                      }`}
                      type="button"
                      onClick={() => {
                        setPaymentVendorType(EPaymentVendorType.COMGATE);
                      }}
                    >
                      {t('basket.payViaComgate')}
                    </button>

                    <button
                      className={`order--button--payment-vendor ${
                        paymentVendorType === EPaymentVendorType.PAYPAL
                          ? 'active'
                          : ''
                      }`}
                      type="button"
                      onClick={() => {
                        setPaymentVendorType(EPaymentVendorType.PAYPAL);
                      }}
                    >
                      {t('basket.payViaPayPal')}
                    </button> */}

                    {Object.keys(errors).length !== 0 ||
                    values.packagesCount === 0 ||
                    values.termsAndConditions !== true ? (
                      <div className="alert">
                        <span>
                          {t(
                            'basket.pleaseCheckYourOrderFormForAllRequiredFields',
                          )}
                        </span>
                      </div>
                    ) : (
                      <>
                        <div className="notification">
                          <span>
                            {t(
                              'basket.yourOrderIsReadyToBeSendYouCanCreateItAndPayViaButtonBelow',
                            )}
                          </span>
                        </div>

                        <button
                          className={`order--button--submit ${
                            Object.keys(errors).length !== 0 ||
                            values.packagesCount === 0 ||
                            values.termsAndConditions !== true
                              ? 'disabled'
                              : ''
                          }`}
                          type="submit"
                          disabled={
                            Object.keys(errors).length !== 0 ||
                            values.packagesCount === 0 ||
                            values.termsAndConditions !== true
                          }
                        >
                          {t(
                            paymentVendorType === EPaymentVendorType.COMGATE
                              ? 'basket.createOrderAndPayViaComgatePrice'
                              : 'basket.createOrderAndPayViaPayPalPrice',
                            {
                              price: currency
                                ? formatCurrency(
                                    basketPrice.total + values.totalDeliveryFee,
                                    currency,
                                  )
                                : round(
                                    basketPrice.total + values.totalDeliveryFee,
                                    2,
                                  ),
                            },
                          )}
                        </button>
                      </>
                    )}
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
}

const mapStateToProps = ({
  Basket,
  Order,
  User,
  Country,
  Currency,
  Language,
}) => ({
  Basket,
  Order,
  User,
  Country,
  Currency,
  Language,
});

export default connect(mapStateToProps)(BasketContainer);
