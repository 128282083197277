import { formatCurrency } from '@mahawi/eshop-common/dist/src/format-currency';
import { notEmpty } from '@mahawi/eshop-common/dist/src/not-empty';
import {
  type ICurrencyPrice,
  type ICustomerCurrencyType,
  type ICustomerProductDetail,
  type ITranslation,
} from '@mahawi/eshop-common/dist/src/types';
import { type Dispatch } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { type IConfigState } from '../reducers/config/types';
import { type ICurrencyState } from '../reducers/currency/types';
import { type ILanguageState } from '../reducers/language/types';
import { load } from '../reducers/product/reducer';
import { clearResults } from '../reducers/search/reducer';
import { type ISearchState } from '../reducers/search/types';
import { type IUserState } from '../reducers/user/types';

function SearchResult({
  Config,
  Currency,
  dispatch,
  Language,
  Search,
  User,
}: {
  Config: IConfigState;
  Currency: ICurrencyState;
  dispatch: Dispatch;
  Language: ILanguageState;
  Search: ISearchState;
  User: IUserState;
}): JSX.Element | null {
  const [rows, setRows] = useState<JSX.Element[]>([]);

  useEffect((): void => {
    const currency: ICustomerCurrencyType | undefined =
      Currency.currencies.find(
        (c: ICustomerCurrencyType): boolean =>
          c.isoCode === User.currencyType?.isoCode,
      );

    const rowsUE: JSX.Element[] = Search.products
      .map((product: ICustomerProductDetail): JSX.Element | null => {
        const productCurrency: ICurrencyPrice | undefined = product.prices.find(
          ({ isoCode }: ICurrencyPrice): boolean =>
            isoCode === User.currencyType?.isoCode,
        );

        const [photo] = product.photos;

        let productLink: string = '';

        const slug: ITranslation | undefined = product.slugs.find(
          (s: ITranslation): boolean => s.code === Language.languageType.code,
        );

        if (slug && slug.value) {
          productLink = `/${Language.languageType.code.toLowerCase()}/p/${
            slug.value
          }`;
        } else {
          productLink = `/${Language.languageType.code.toLowerCase()}/product/${
            product.uuid
          }`;
        }

        const productName: ITranslation | undefined = product.names.find(
          ({ code }: ITranslation): boolean =>
            code === Language.languageType.code,
        );

        if (!productName || !productName.value) {
          return null;
        }

        return (
          <li key={product.uuid}>
            <Link to={productLink}>
              <button
                type="button"
                onClick={(): void => {
                  dispatch(
                    load({
                      product: {
                        uuid: product.uuid,
                      },
                    }),
                  );
                }}
              >
                <div className="searchResultImageContainer">
                  {photo && photo.uuid ? (
                    <img
                      alt={
                        product.names.find(
                          ({ code }: ITranslation): boolean =>
                            code === Language.languageType.code,
                        )?.value
                      }
                      title={
                        product.names.find(
                          ({ code }: ITranslation): boolean =>
                            code === Language.languageType.code,
                        )?.value
                      }
                      src={`${Config.cdn}/static/product/photo/${product.photos[0].uuid}--100.webp`}
                      loading="lazy"
                    />
                  ) : null}
                </div>
                <span className="searchResultName">
                  {
                    product.names.find(
                      ({ code }: ITranslation): boolean =>
                        code === Language.languageType.code,
                    )?.value
                  }
                </span>
                <span className="searchResultPrice">
                  {productCurrency
                    ? formatCurrency(productCurrency.price, currency)
                    : ''}
                </span>
              </button>
            </Link>
          </li>
        );
      })
      .filter(notEmpty);

    setRows(rowsUE);
  }, [
    Config.cdn,
    Currency.currencies,
    dispatch,
    Language.languageType.code,
    Search.products,
    User.currencyType?.isoCode,
  ]);

  if (Search.products.length === 0 && Search.query.trim().length === 0) {
    return null;
  }

  if (Search.products.length === 0 && Search.query.trim().length > 0) {
    return (
      <div
        role="presentation"
        className="searchOverlay"
        onClick={() => dispatch(clearResults())}
      />
    );
  }

  return (
    <>
      <div
        role="presentation"
        className="searchOverlay"
        onClick={() => dispatch(clearResults())}
      />
      <ul
        role="presentation"
        className="searchResult"
        onClick={() => dispatch(clearResults())}
      >
        {rows}
      </ul>
    </>
  );
}

const mapStateToProps = ({ Currency, Language, Search, User, Config }) => ({
  Currency,
  Language,
  Search,
  User,
  Config,
});

export default connect(mapStateToProps)(SearchResult);
